import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useToggle from 'hooks/useToggle'

const Collapsible = ({title, children, defaultIsOpen}) => {
  const {isOpen, toggle} = useToggle(defaultIsOpen)

  return (
    <div className="box p-0">
      <div
        className="p-2 has-background-light has-cursor-pointer"
        onClick={toggle}>
        {title}
      </div>
      <div
        className={classNames('content is-clipped has-background-white', {
          'p-2': isOpen,
        })}
        style={{height: isOpen ? null : 0}}>
        {children}
      </div>
    </div>
  )
}

Collapsible.defaultProps = {
  defaultIsOpen: false,
}

Collapsible.propTypes = {
  children: PropTypes.node.isRequired,
  defaultIsOpen: PropTypes.bool,
  title: PropTypes.node.isRequired,
}

export default Collapsible
