import React, {memo} from 'react'
import classNames from 'classnames'

import styles from '../styles.module.scss'

const Demo = memo(() => {
  return (
    <section
      className={classNames(
        'section has-background-warning has-text-green',
        styles.demoContainer
      )}>
      <div className="container is-container">
        <div className="columns is-vcentered">
          <div className="column">
            <figure className={classNames('image', styles.featureImage)}>
              <img src="/assets/gif/demo.gif" alt="Demo" />
            </figure>
          </div>
          <div className="column">
            <h3 className="is-size-3 is-family-calistoga">Intuitive UI</h3>
            <p className="paragraph">
              Creating a new chat link is as easy, using the chat is even
              easier. We have made sure that the interface looks and feels like
              other chat platforms that you are used to, while ensuring all
              features are available to you no matter which device you are
              using.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Demo
