export default [
  {
    title: 'How do I use this?',
    content: `- Click on \`GET YOUR CHAT LINK\` button or navigate to \`Create\` link page using the menu.
- Fill out all the basic details about the chat including a PIN. An encryption key would be generated and you'd be redirected to a unique chat link.
- Share this unique link with whomever you want to chat with. Don't forget to also tell them the unique PIN, otherwise they would not be able to chat with you.
- For your convenience, once your unique chat link is created, you'll be shown with information about your chat that you can share directly with others. You can also click the \`Share\` icon in the chat window to view this information at any time.`,
  },
  {
    title:
      'Why do I need this, I already have WhatsApp, Telegram, ... for chat?',
    content: `For starters, you need to ask others to also install the same app in order to chat with them. Also you need some sort of account creation process to be able to identify others in order to talk to them.
    
Using **reden.me** platform, no one needs to install any app on their phones or create any account. All you need is an internet connection to get talking with someone else.`,
  },
  {
    title: 'Can you elaborate the privacy and encryption features?',
    content: `One of the biggest pitfalls of using any of the free chat apps out there is handing over your raw data to someone else. Doing so, not only is a greater threat to your privacy, it also means that your data could be used for all sorts of marketing, targeted advertisements and what not. What's worse is the fact that it's possible that a hacker could also breach the system to find your data and use it for anything evil.

We have built this platform with privacy first approach. Not only your chats are [end-to-end encrypted](https://en.wikipedia.org/wiki/End-to-end_encryption) with [RSA encryption](https://en.wikipedia.org/wiki/RSA_(cryptosystem)), we take extra steps to ensure that all encryption and decryption operations happen exclusively on your device and as such, not even we have access to any of your data. Furthermore, the encryption keys are generated locally on your device, so the secret PIN never leaves your device, and ultimately it's known only to you. Unless you share the unique chat PIN with someone, no-one else would be able to see any of those messages. Even if the platform suffers a breach, everything is anonymous and the messages are meaningless without the decryption key and the PIN which is not available on the server and is known only to you.`,
  },
  {
    title: `What kind of information can I send in the chats?`,
    content: `Currently the chat supports the following types of data.
- All kinds of texts and [markdown](https://en.wikipedia.org/wiki/Markdown) formatted texts.
- All basic emojis. You can type \`:\` to bring up the emoji autocomplete while composing your messages.
  
We are working on bringing the following support in the future.
  - Sharing images and videos.
  - [P2P](https://en.wikipedia.org/wiki/Peer-to-peer) audio and video chat.`,
  },
  {
    title: 'Does this support group chat?',
    content: `Yes, in fact every chat can be used as a group chat. Since there are no user accounts associated with the chat, each time you use a different browser or device to visit the same chat, you are actually treated as a new user to the chat.`,
  },
  {
    title: 'What happens if I forget the PIN for a unique chat?',
    content: `Unfortunately you'd not be able to recover any of the messages without the PIN. Since we do not store your chat PINs and all the messages are encrypted on your device using the chat specific unique PIN, it's important you understand the trade offs we made in order to ensure 100% security guarantee. Your best bet is to ask the other person with whom you have shared the unique chat link.`,
  },
  {
    title: 'Can I use my own encryption keys?',
    content: `Not at the moment. But in future we would possibly allow uploading your own encryption keys for encrypting all your chat messages.`,
  },
  {
    title: 'Who is behind all this, is it backed by any company?',
    content: `Currently it's just [me](https://emad.in) handling all operations and development of this platform. Since all of this is built using open source technologies, I plan to eventually open source this platform once things have reached a certain level of perfection. Please reach out to <dev@reden.me> in case you wish to collaborate or help with this project.`,
  },
  {
    title: 'How does this platform survive without backers or revenue?',
    content: `To be honest, the goal of this platform is not to generate any revenue but to give back to the community. Every bit of this platform is built using open source technologies, including the operational side of things. As such, there is absolutely no to minimum operational cost for keeping this platform alive. We have a strong affinity towards open source technologies and see this as an opportunity to give back to the open source community.`,
  },
  {
    title: 'It all sounds too good to be true, how can I verify the claims?',
    content: `All you need is a little bit of knowledge regarding network requests and [Chrome DevTools](https://developers.google.com/web/tools/chrome-devtools).

**Chat link creation**
- Open the Chrome DevTools and switch to the \`Network\` tab.
- Navigate to the Create page to create a new chat link.
- Fill out the details and start the chat link creation process.
- In the meantime observe all network requests to verify that the PIN was never sent over the network.

**Messages**
- Open the Chrome DevTools and switch to the \`Network\` tab.
- Navigate to any of your unique chat links.
- Add messages and observe the network requests to verify that the message texts are encrypted and the PIN was never shared over the network.`,
  },
  {
    title: 'I have a question or feedback, whom should I contact?',
    content: `Send all your queries to <support@reden.me> and we'd get back to you as soon as we can.`,
  },
]
