import React, {memo} from 'react'
import classNames from 'classnames'
import Link from 'next/link'

import {HELP_SECTION_URL} from 'utils/const'

import styles from '../styles.module.scss'

const Hero = memo(() => {
  return (
    <div className="columns">
      <div className="column is-half">
        <h1
          className={classNames(
            'has-text-green is-size-1 is-size-3-mobile is-family-calistoga'
          )}>
          Click; Share; Talk!
        </h1>
        <h2 className="subtitle has-text-green is-family-calistoga">
          Privacy first, sharable chat links.
        </h2>
        <p className="has-text-green has-text-justified paragraph">
          Free and <strong>encrypted chat, no login, no installations</strong>{' '}
          needed. Create unlimited private chat links for free and start talking
          with anyone, anywhere.
        </p>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <Link href="/chats/create">
                <button
                  className={classNames(
                    'button is-rounded is-fullwidth has-background-green has-text-white has-letter-spacing is-uppercase',
                    styles.cta
                  )}>
                  Get Your Chat Link
                </button>
              </Link>
            </div>
            <div className="level-item">
              <Link href={HELP_SECTION_URL}>
                <button
                  className={classNames(
                    'button is-rounded is-white is-fullwidth has-letter-spacing is-uppercase',
                    styles.cta
                  )}>
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="column" />
    </div>
  )
})

export default Hero
