import React, {memo} from 'react'
import classNames from 'classnames'

import styles from '../styles.module.scss'

const Features = memo(() => {
  return (
    <section className="section has-background-mustard has-text-green ">
      <div className="container is-container">
        <div className="columns reverse-row-order-mobile mb-2">
          <div className="column">
            <h3 className="is-size-3 is-family-calistoga">
              Open Source, all the way
            </h3>
            <p className="paragraph">
              The platform is built using all the latest and greatest Open
              Source technologies. As such there are no hidden black box, no
              secret code, no surprises about this platform. Furthermore, we
              plan to also Open Source this platform itself to make everything
              transparent to everyone using the platform.
            </p>
          </div>
          <div className="column">
            <figure className={classNames('image', styles.featureImage)}>
              <img src="/assets/img/open-source.svg" alt="feature" />
            </figure>
          </div>
        </div>
      </div>
      <div className="container is-container">
        <div className="columns mb-2">
          <div className="column">
            <figure className={classNames('image', styles.featureImage)}>
              <img src="/assets/img/secure-auth.svg" alt="feature" />
            </figure>
          </div>
          <div className="column">
            <h3 className="is-size-3 is-family-calistoga">
              Privacy First &amp; Secure
            </h3>
            <p className="paragraph">
              Unlike other chat platforms, <b>reden.me</b> takes extra steps to
              ensure your privacy and secure your messages. All your messages
              are end-to-end encrypted with encryption keys that are generated
              on your device. Your secret PINs are never stored or sent over the
              network, not even to our servers.
            </p>
          </div>
        </div>
      </div>
      <div className="container is-container">
        <div className="columns reverse-row-order-mobile">
          <div className="column">
            <h3 className="is-size-3 is-family-calistoga">
              Free &amp; Cross platform
            </h3>
            <p className="paragraph">
              The service is totally free and does not require any account
              creation or subscriptions. Furthermore, there is no need to
              install any app on your device. All you need is a working internet
              connection to get talking with others.
            </p>
          </div>
          <div className="column">
            <figure className={classNames('image', styles.featureImage)}>
              <img src="/assets/img/friends-online.svg" alt="feature" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Features
