import React from 'react'
import classNames from 'classnames'

import Layout from 'modules/common/components/Layout'
import Hero from './components/Hero'
import Features from './components/Features'
import FAQs from './components/FAQs'
import Demo from './components/Demo'

import styles from './styles.module.scss'

const HomePage = () => {
  return (
    <Layout
      containerClassName="has-text-centered-mobile"
      heroContainerClassName="is-medium is-fullheight"
      heroBodyClassName={classNames(
        'has-default-background-image-mobile',
        styles.heroBody
      )}
      hero={<Hero />}>
      <Features />
      <Demo />
      <FAQs />
    </Layout>
  )
}

export default HomePage
