import React, {memo} from 'react'
import ReactMarkdown from 'react-markdown'
import map from 'lodash/map'

import Collapsible from 'components/Collapsible'
import faqs from './faqs'

const faqNodes = map(faqs, ({title, content}, index) => (
  <div className="column is-half" key={index}>
    <Collapsible title={<h6 className="title is-6">{title}</h6>}>
      <ReactMarkdown source={content} linkTarget="_blank" />
    </Collapsible>
  </div>
))

const FAQs = memo(() => {
  return (
    <section className="section has-background-white">
      <div className="container is-container">
        <a nama="#faq-section" id="faq-section" />
        <h3 className="is-size-3 is-family-calistoga mb-1">FAQs</h3>
        <div className="columns is-multiline has-text-left">{faqNodes}</div>
      </div>
    </section>
  )
})

export default FAQs
